import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import PosLogo from "assets/posLogo.png";
import ArrowLink from "assets/arrowLink.png";
import BridgeLogo from "assets/bridgeLogo.png";
import { useQueryStatsInfo } from "utils/queries";
import { useMemo } from "react";
import numeral from "numeral";
import DataLoading from "assets/data-loading.png";
import PosLogo_Light from "assets/posLogo_light.png";
import BridgeLogo_Light from "assets/bridgeLogo_light.png";
import { useLocalStorage } from "context/localstorage-context";

export default function MainBody() {
  const { data: statsInfo, isLoading } = useQueryStatsInfo();
  const { theme } = useLocalStorage();

  const storemanTvl = useMemo(() => {
    if (statsInfo && statsInfo.sotremen) {
      return numeral(
        statsInfo.sotremen.totalDepositRaw / Math.pow(10, 18)
      ).format("0,0");
    } else {
      return 0;
    }
  }, [statsInfo]);

  const posTvl = useMemo(() => {
    if (statsInfo && statsInfo.pos) {
      return numeral(statsInfo.pos.curTotalAmountRaw / Math.pow(10, 18)).format(
        "0,0"
      );
    } else {
      return 0;
    }
  }, [statsInfo]);

  const isDark = useMemo(() => theme === "dark", [theme]);

  return (
    <Container>
      <PosContent className="bg-white dark:bg-common-1">
        <LogoCon>
          <Img src={isDark ? PosLogo : PosLogo_Light} alt="logo" width={136} />
        </LogoCon>
        <Title bgColor={isDark ? "#fff" : "#818D96"}>
          · Galaxy Consensus ·
        </Title>
        <PosData>
          {isLoading ? (
            <img src={DataLoading} alt="loading" width="100" />
          ) : (
            posTvl
          )}
        </PosData>
        <Text1>Total Staked Amount (WAN)</Text1>
        <Describ bgColor={"#818D96"}>
          Receive staking rewards by delegating your WAN to Wanchain PoS nodes.
          Your delegated WAN contributes to the overall stability and security
          of the Wanchain blockchain network.
        </Describ>
        <StyledNavLink to={"/pos"}>
          <BtnLink>
            Delegate to POS
            <img src={ArrowLink} alt="group" width={24} />
          </BtnLink>
        </StyledNavLink>
      </PosContent>
      <BridgeContent className="bg-white dark:bg-common-1">
        <LogoCon>
          <Img
            src={isDark ? BridgeLogo : BridgeLogo_Light}
            alt="logo"
            width={136}
          />
        </LogoCon>
        <Title bgColor={isDark ? "#fff" : "#818D96"}>
          · Universal Bridges ·
        </Title>
        <BridgeData>
          {isLoading ? (
            <img src={DataLoading} alt="loading" width="100" />
          ) : (
            storemanTvl
          )}
        </BridgeData>
        <Text1>Total Staked Amount (WAN)</Text1>
        <Describ bgColor={"#818D96"}>
          Receive staking rewards by delegating your WAN to Wanchain Bridge
          nodes. Your delegated WAN enhances the bandwidth, security and
          stability of Wanchain's cross-chain bridges.
        </Describ>
        <StyledNavLink to={"/bridge"}>
          <BtnLink>
            Delegate to Bridge
            <img src={ArrowLink} alt="group" width={24} />
          </BtnLink>
        </StyledNavLink>
      </BridgeContent>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 0 5%;
  height: 440px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
    margin-bottom: 5rem;
    margin-top: 400px;
  }
`;

const PosContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 24px;
  padding: 30px 10px;
  width: 45%;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5rem;
  }
`;

const BridgeContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 24px;
  padding: 30px 10px;
  width: 45%;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5rem;
  }
`;

const Title = styled.p<{
  bgColor: string;
}>`
  font-size: 24px;
  font-family: Inter-Black, Inter;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0px;
  color: ${(props) => props.bgColor};
`;

const StyledNavLink = styled(NavLink)`
  outline: none;
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  height: 60px;
  line-height: 60px;
  position: absolute;
  bottom: -30px;
`;

const PosData = styled.div`
  font-size: 50px;
  font-family: Inter-Black, Inter;
  font-weight: 900;
  color: #0f68aa;
`;

const BridgeData = styled.div`
  font-size: 50px;
  font-family: Inter-Black, Inter;
  font-weight: 900;
  color: #2fbdf4;
`;

const Describ = styled.div<{
  bgColor: string;
}>`
  font-size: 16px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  line-height: 19px;
  width: 95%;
  color: ${(props) => props.bgColor};
`;

const Text1 = styled.div`
  font-size: 14px;
  font-family: Inter-Black, Inter;
  font-weight: 400;
  color: #818d96;
  margin-bottom: 1rem;
`;

const LogoCon = styled.div`
  width: 100%;
`;

const BtnLink = styled.div`
  width: 80%;
  background-color: #0f68aa;
  border-radius: 16px;
  color: #fff;
`;

const Img = styled.img`
  display: inline-block;
`;
