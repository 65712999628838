import styled from "@emotion/styled";
import { Button, notification } from "antd";
import { useMemo, useState } from "react";
import DelegateModal from "./delegate-modal";
import DelegateConfirm from "./delegate-confirm";
import { useQueryPosStakeInfo } from "utils/queries";
import { useLocation } from "react-router";
import { useSendPosContractFunc } from "utils/useWeb3";
import { useWallet } from "context/wallet-context";
import BigNumber from "bignumber.js";
import { useDelegatorStakeInfo, useDelegatorTotalIncentive } from "./pos-data";
import Numeral from "numeral";
import { useQueryClient } from "react-query";
import { PoSDataToolTip } from "config";
import ToolTipCus from "components/tooltips";
import { useLocalStorage } from "context/localstorage-context";
import BackImg from "assets/back.png";
import BackImg_Light from "assets/back_light.png";
import LinkIcon from "assets/link.png";
import success_icon from "assets/success_icon.png";
import error_icon from "assets/error_icon.png";

const Img = styled.img`
  top: -1px;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
`;

const Link = ({ txHash }: { txHash: string }) => (
  <div>
    Veiw on Wanchain Explorer{" "}
    <a
      target="_blank"
      rel="noreferrer"
      href={`${process.env.REACT_APP_WANSCAN_API}/tx/${txHash}`}
    >
      <Img width={18} src={LinkIcon} alt="link" />
    </a>
  </div>
);

export default function PosDetail() {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showWithdrawConfirmModal, setShowWithdrawConfirmModal] =
    useState(false);
  const { data: posStakeInfo } = useQueryPosStakeInfo();
  const delegatorStakeInfo = useDelegatorStakeInfo();
  const delegatorTotalIncentive = useDelegatorTotalIncentive();
  const client = useSendPosContractFunc();
  const { connected, connect } = useWallet();
  const queryClient = useQueryClient();
  const { theme } = useLocalStorage();

  const isdark = useMemo(() => theme === "dark", [theme]);

  const stakerInfo = useMemo(() => {
    if (
      posStakeInfo &&
      posStakeInfo.stakers &&
      posStakeInfo.stakers.length > 0
    ) {
      const staker = posStakeInfo.stakers.find(
        (v: any) => v.address === location.pathname.split("/posdetail/")[1]
      );
      if (staker) {
        staker.myDelegate = "0";
        staker.myTotalIncentive = "0";
        delegatorStakeInfo.forEach((j: any) => {
          if (staker.address.toLowerCase() === j.address.toLowerCase()) {
            staker.myDelegate = j.amount.toString();
          }
        });
        delegatorTotalIncentive.forEach((t: any) => {
          if (staker.address.toLowerCase() === t.address.toLowerCase()) {
            staker.myTotalIncentive = new BigNumber(staker.myTotalIncentive)
              .plus(t.amount)
              .toString();
          }
        });
      } else {
        return [];
      }
      return staker || {};
    } else {
      return {};
    }
  }, [
    delegatorStakeInfo,
    delegatorTotalIncentive,
    location.pathname,
    posStakeInfo,
  ]);

  const posDelegateIn = (amount: string) => {
    const amountInWei = new BigNumber(amount)
      .multipliedBy(Math.pow(10, 18))
      .toString(10);
    client("delegateIn", [stakerInfo.address], amountInWei)
      .on("receipt", (receipt: any) => {
        if (receipt && receipt.status) {
          notification.success({
            message: "Transaction completed",
            description: <Link txHash={receipt.transactionHash} />,
            className: `${
              isdark ? "noticeSuccess_dark" : "noticeSuccess_light"
            }`,
            icon: <img width={30} src={success_icon} alt="notification" />,
          });
          queryClient.invalidateQueries("getDelegatorStakeInfo");
          setShowTopUpModal(false);
        } else {
          notification.error({
            message: "Transaction Sent Failed",
            description: <Link txHash={receipt.transactionHash} />,
            className: `${
              isdark ? "noticeSuccess_dark" : "noticeSuccess_light"
            }`,
            icon: <img width={30} src={error_icon} alt="notification" />,
          });
        }
      })
      .on("error", () => {
        setShowTopUpModal(false);
      });
  };

  const posWithdraw = () => {
    client("delegateOut", [stakerInfo.address])
      .on("receipt", (receipt: any) => {
        if (receipt && receipt.status) {
          notification.success({
            message: "Transaction completed",
            description: <Link txHash={receipt.transactionHash} />,
            className: `${
              isdark ? "noticeSuccess_dark" : "noticeSuccess_light"
            }`,
            icon: <img width={30} src={success_icon} alt="notification" />,
          });
          queryClient.invalidateQueries("getDelegatorStakeInfo");
          setShowWithdrawConfirmModal(false);
        } else {
          notification.error({
            message: "Transaction Sent Failed",
            description: <Link txHash={receipt.transactionHash} />,
            className: `${
              isdark ? "noticeSuccess_dark" : "noticeSuccess_light"
            }`,
            icon: <img width={30} src={error_icon} alt="notification" />,
          });
        }
      })
      .on("error", (error: any) => {
        setShowWithdrawConfirmModal(false);
      });
  };

  const handleTopUp = async () => {
    if (connected) {
      setShowTopUpModal(true);
    } else {
      await connect();
      setShowTopUpModal(true);
    }
  };

  const handleWithdraw = async () => {
    if (connected) {
      setShowWithdrawConfirmModal(true);
    } else {
      await connect();
      setShowWithdrawConfirmModal(true);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  const feeRate = useMemo(() => {
    let tmp;
    try {
      tmp = Numeral(stakerInfo.feeRateFmt)
        .multiply(100)
        .format("0,0.00")
        .replace(/\.?0+$/, "");
    } catch (error) {
      tmp = stakerInfo.feeRateFmt;
    }
    return tmp;
  }, [stakerInfo.feeRateFmt]);

  const MaxFeeRate = useMemo(() => {
    let tmp;
    try {
      tmp = Numeral(stakerInfo.maxFeeFmt)
        .multiply(100)
        .format("0,0.00")
        .replace(/\.?0+$/, "");
    } catch (error) {
      tmp = stakerInfo.maxFeeFmt;
    }
    return tmp;
  }, [stakerInfo.maxFeeFmt]);

  return (
    <MainBody>
      <BackBtnContainer>
        <BackBtn onClick={goBack}>
          <div
            className="bg-white dark:bg-common-1"
            style={{ width: "32px", borderRadius: "5px" }}
          >
            <img src={isdark ? BackImg : BackImg_Light} alt="back" width={32} />
          </div>
        </BackBtn>
      </BackBtnContainer>
      <DetailAddr className="bg-white dark:bg-dark-222">
        <DetailAddr1>
          <Icon
            src={`data:image/${stakerInfo.iconType};base64,${stakerInfo.iconData}`}
            alt="icon"
          />
        </DetailAddr1>
        <DetailAddr2>
          <p
            className="text-light-0 dark:text-white"
            style={{
              fontSize: "24px",
              wordBreak: "break-word",
              textAlign: "left",
            }}
          >
            {stakerInfo.name}
          </p>
          <p
            className="text-light-333 dark:text-dark-0"
            style={{
              color: "#757575",
              wordBreak: "break-word",
              textAlign: "left",
            }}
          >
            {stakerInfo.address}
          </p>
        </DetailAddr2>
      </DetailAddr>
      <DetailInfo className="bg-white dark:bg-dark-222">
        {/********* / Part1  *********/}
        <InfoRow>
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Total Staked Amount
              <ToolTipCus title={PoSDataToolTip[0]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {stakerInfo.totalAmountFmt}
              </span>
              <span>WAN</span>
            </p>
          </Data>
          <LineCloumn isdark={isdark} />
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Remaining Quota
              <ToolTipCus title={PoSDataToolTip[1]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {stakerInfo.quotaLeft}
              </span>
              <span>WAN</span>
            </p>
          </Data>
        </InfoRow>
        <LineRow isdark={isdark} />

        {/********* / Part2  *********/}
        <InfoRow>
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Activity
              <ToolTipCus title={PoSDataToolTip[2]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {stakerInfo.activity}
              </span>
            </p>
          </Data>
          <LineCloumn isdark={isdark} />
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Lock Period
              <ToolTipCus title={PoSDataToolTip[3]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {stakerInfo.lockEpochs}
              </span>
              <span>{stakerInfo.lockEpochs > 1 ? "DAYS" : "DAY"}</span>
            </p>
          </Data>
        </InfoRow>
        <LineRow isdark={isdark} />

        {/********* / Part3  *********/}
        <InfoRow>
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Delegation Fee (%)
              <ToolTipCus title={PoSDataToolTip[4]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">{feeRate}</span>
            </p>
          </Data>
          <LineCloumn isdark={isdark} />
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Max Delegation Fee(%)
              <ToolTipCus title={PoSDataToolTip[5]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">{MaxFeeRate}</span>
            </p>
          </Data>
        </InfoRow>
        <LineRow isdark={isdark} />

        {/********* / Part4  *********/}
        <InfoRow>
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Status
              <ToolTipCus title={PoSDataToolTip[6]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {stakerInfo.currentStatus}
              </span>
            </p>
          </Data>
          <LineCloumn isdark={isdark} />
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Ends In
              <ToolTipCus title={PoSDataToolTip[7]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {stakerInfo.nextStatus}
              </span>
            </p>
          </Data>
        </InfoRow>
      </DetailInfo>
      {new BigNumber(stakerInfo.myDelegate).eq("0") ? (
        <Btn>
          <Button block onClick={handleTopUp}>
            Delegate
          </Button>
        </Btn>
      ) : (
        <MyDelegate className="bg-white dark:bg-dark-222">
          <InfoRow count={2} direction="column">
            <Data1>
              <DataTitle className="text-light-444 dark:text-dark-0">
                My Rewards
              </DataTitle>
              <div>
                <span className="text-light-111 dark:text-white">
                  {Numeral(stakerInfo.myTotalIncentive)
                    .format("0,0.0000")
                    .replace(/\.?0+$/, "")}
                </span>
                <span className="text-light-444 dark:text-dark-0">WAN</span>
              </div>
            </Data1>
          </InfoRow>
          <LineRow isdark={isdark} />
          <InfoRow count={2} direction="column">
            <Data1>
              <DataTitle className="text-light-444 dark:text-dark-0">
                My Delegations
              </DataTitle>
              <MyActions>
                <div>
                  <span className="text-light-111 dark:text-white">
                    {Numeral(stakerInfo.myDelegate)
                      .format("0,0.0000")
                      .replace(/\.?0+$/, "")}
                  </span>
                  <span className="text-light-444 dark:text-dark-0">WAN</span>
                </div>
                <BtnArea isdark={isdark}>
                  <Button onClick={handleTopUp}>Top-up</Button>
                  <Button onClick={handleWithdraw}>
                    <TextGradient>Undelegate</TextGradient>
                  </Button>
                </BtnArea>
              </MyActions>
            </Data1>
          </InfoRow>
        </MyDelegate>
      )}
      {showModal && (
        <DelegateModal
          type="Delegate"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          stakerInfo={stakerInfo}
          action={() => {}}
        />
      )}
      {showTopUpModal && (
        <DelegateModal
          type={
            new BigNumber(stakerInfo.myDelegate).eq("0") ? "Delegate" : "Top-up"
          }
          visible={showTopUpModal}
          onCancel={() => setShowTopUpModal(false)}
          stakerInfo={stakerInfo}
          action={(v: string) => posDelegateIn(v)}
        />
      )}
      {showWithdrawConfirmModal && (
        <DelegateConfirm
          visible={showWithdrawConfirmModal}
          onCancel={() => setShowWithdrawConfirmModal(false)}
          stakerInfo={stakerInfo}
          action={posWithdraw}
          amount={stakerInfo.myDelegate}
          type="Undelegate WAN"
        />
      )}
    </MainBody>
  );
}

const MainBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  text-align: center;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const BackBtnContainer = styled.div`
  width: 80rem;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 16px;
  }
`;

const BackBtn = styled.div`
  cursor: pointer;
  width: 32px;
`;

const DetailAddr = styled.div`
  width: 80rem;
  border-radius: 16px;
  backdrop-filter: blur(50px);
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const DetailInfo = styled.div`
  width: 80rem;
  height: 35rem;
  border-radius: 16px;
  backdrop-filter: blur(50px);
  padding: 5px 20px;

  @media (max-width: 768px) {
    width: 100%;
    height: 95rem;
  }
`;

const Btn = styled.div`
  width: 80rem;
  .ant-btn {
    border-radius: 16px;
    height: 60px;
    color: white;
    border: none;
    background: #0f68aa;
  }
  .ant-btn:hover {
    background: #0f68aa;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 2rem;
  }
`;

const MyDelegate = styled.div`
  width: 80rem;
  border-radius: 16px;
  opacity: 1;
  padding: 10px 20px;
  backdrop-filter: blur(30px);
  gap: 1rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DetailAddr1 = styled.div`
  width: 90px;
  line-height: 90px;
`;

const DetailAddr2 = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 90px;
  justify-content: center;
  align-items: flex-start;
  p {
    margin-bottom: 0;
  }
`;

const InfoRow = styled.div<{
  count?: number;
  direction?: string;
}>`
  width: 100%;
  display: flex;
  height: ${(props) => `calc(100% / ${props.count || 4})`};
  flex-direction: ${(props) => props.direction || "row"};

  @media (max-width: 768px) {
    flex-direction: column;
    height: initial;
    justify-content: space-around;
    margin: 2rem 0;
  }
`;

const LineRow = styled.div<{
  isdark: boolean;
}>`
  border-bottom: ${(props) =>
    props.isdark ? "1px solid #1E3546" : "1px solid #EBEFF2"};
  width: 100%;
`;

const Data = styled.div`
  display: inline-block;
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin-bottom: 0;
    font-weight: bold;

    span:first-of-type {
      font-size: 32px;
      display: inline-block;
      margin-right: 10px;
    }
    span:not(:first-of-type) {
      font-size: 12px;
      color: #757575;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LineCloumn = styled.div<{
  isdark: boolean;
}>`
  height: 100%;
  border-left: ${(props) =>
    props.isdark ? "1px solid #1E3546" : "1px solid #EBEFF2"};
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100;
    border-bottom: ${(props) =>
      props.isdark ? "1px solid #1E3546" : "1px solid #EBEFF2"};
    border-left: 0;
  }
`;

const DataTitle = styled.span<{
  color?: string;
}>`
  font-size: 16px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${(props) => props.color || "#757575"};
  position: relative;
`;

const BtnArea = styled.div<{
  isdark: boolean;
}>`
  display: inline-flex;
  justify-content: space-between;
  gap: 20px;

  span {
    font-size: 16px !important;
    display: inline-block;
    margin-right: 0 !important;
  }
  .ant-btn:last-of-type {
    background: ${(props) => (props.isdark ? "#051f31" : "#fff")};
    border-radius: 16px;
    border: 1px solid #2fbdf4;
    color: #2fbdf4;
  }
  .ant-btn:last-of-type:hover {
    background: ${(props) => (props.isdark ? "#051f31" : "#fff")};
  }
  .ant-btn {
    width: 120px;
    border-radius: 16px;
    height: 30px;
    color: white;
    border: none;
    background: #0f68aa;
  }
  .ant-btn:hover {
    background: #0f68aa;
  }

  @media (max-width: 768px) {
    justify-content: space-around;
    margin-top: 2rem;
  }
`;

const Data1 = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    margin-bottom: 0;
    font-weight: bold;

    span:first-of-type {
      font-size: 32px;
      display: inline-block;
      margin-right: 10px;
    }
    span:not(:first-of-type) {
      font-size: 12px;
      color: #757575;
    }
  }
`;

const Icon = styled.img`
  width: 60px;
  border-radius: 8px;
`;

const TextGradient = styled.span`
  color: #2fbdf4;
`;

const MyActions = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
